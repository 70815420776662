import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();
  const [navBarHeight, setNavBarHeight] = useState(204);
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => { 
    let heightFromTop = window.innerWidth;

    if(heightFromTop > 992) {
      setNavBarHeight(224);
    } else if(heightFromTop > 768) {
      setNavBarHeight(204);
    } else {
      setNavBarHeight(null);
    }
  }, [])
  useEffect(() => {
    if(pathname !== '/' && navBarHeight !== null) {
      window.scrollTo({top: navBarHeight, left: 0, behavior: 'instant'});
    }
  }, [pathname]);
}

export default ScrollToTop;